import React from "react";
import { connect } from "react-redux";
import AbtBanner from "../../assets/img/img_abtbanr.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import FinReport from "../../assets/img/rep.jpg";
import AnnReport from "../../assets/img/ann.jpg";
import ANNUAL21 from "../../assets/Annual2021.pdf";
import FINANCIAL21 from "../../assets/Financial2021.pdf";
import ANNUAL22 from "../../assets/Annual2022.pdf";
import FINANCIAL22 from "../../assets/Financial2022.pdf";
import ANNUAL19 from "../../assets/Annual2019.pdf";
import FINANCIAL19 from "../../assets/Financial2019.pdf";
import ANNUAL20 from "../../assets/Annual2020.pdf";
import FINANCIAL20 from "../../assets/Financial2020.pdf";
import ANNUAL18 from "../../assets/Annual2018.pdf";
import FINANCIAL18 from "../../assets/Financial2018.pdf";
import ANNUAL17 from "../../assets/Annual2017.pdf";
import FINANCIAL17 from "../../assets/Financial2017.pdf";
import { history } from "../../../helpers";
import { Document, Page, pdfjs } from "react-pdf";
import AboutUsBanner from "../../assets/img/Banner/AboutUs/AboutUsBanner.png";
import REPORT_BANNER from "../../assets/img/NewBanner/AboutAnnualReport.png";

class OurFinancialReport extends React.Component {
  constructor(props) {
    super(props);
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    this.state = {
      numPages: null,
      pageNumber: 1,
      numPages1: null,
      pageNumber1: 1,
      numPages2: null,
      pageNumber2: 1,
      numPages3: null,
      pageNumber3: 1,
      numPages4: null,
      pageNumber4: 1,
      numPages5: null,
      pageNumber5: 1,
      numPages6: null,
      pageNumber6: 1,
      numPages7: null,
      pageNumber7: 1,
      numPages8: null,
      pageNumber8: 1,
      numPages0: null,
      pageNumber9: 1,
    };
  }
  onDocumentLoad = ({ numPages }) => {
    this.setState({ numPages });
  };
  onDocumentLoad1 = ({ numPages1 }) => {
    this.setState({ numPages1 });
  };
  onDocumentLoad2 = ({ numPages2 }) => {
    this.setState({ numPages2 });
  };
  onDocumentLoad3 = ({ numPages3 }) => {
    this.setState({ numPages3 });
  };
  onDocumentLoad4 = ({ numPages4 }) => {
    this.setState({ numPages4 });
  };
  onDocumentLoad5 = ({ numPages5 }) => {
    this.setState({ numPages5 });
  };
  onDocumentLoad6 = ({ numPages6 }) => {
    this.setState({ numPages6 });
  };
  onDocumentLoad7 = ({ numPages7 }) => {
    this.setState({ numPages7 });
  };
  onDocumentLoad8 = ({ numPages6 }) => {
    this.setState({ numPages6 });
  };
  onDocumentLoad9 = ({ numPages7 }) => {
    this.setState({ numPages7 });
  };
  goToPrevPage = () =>
    this.setState((state) => ({ pageNumber: state.pageNumber - 1 }));
  goToNextPage = () =>
    this.setState((state) => ({ pageNumber: state.pageNumber + 1 }));
  goToPrevPage1 = () =>
    this.setState((state) => ({ pageNumber1: state.pageNumber1 - 1 }));
  goToNextPage1 = () =>
    this.setState((state) => ({ pageNumber1: state.pageNumber1 + 1 }));
  goToPrevPage2 = () =>
    this.setState((state) => ({ pageNumber2: state.pageNumber2 - 1 }));
  goToNextPage2 = () =>
    this.setState((state) => ({ pageNumber2: state.pageNumber2 + 1 }));
  goToPrevPage3 = () =>
    this.setState((state) => ({ pageNumber3: state.pageNumber3 - 1 }));
  goToNextPage3 = () =>
    this.setState((state) => ({ pageNumber3: state.pageNumber3 + 1 }));
  goToPrevPage4 = () =>
    this.setState((state) => ({ pageNumber4: state.pageNumber4 - 1 }));
  goToNextPage4 = () =>
    this.setState((state) => ({ pageNumber4: state.pageNumber4 + 1 }));
  goToPrevPage5 = () => {
    this.setState((state) => ({ pageNumber5: state.pageNumber5 - 1 }));
  };
  goToNextPage5 = () => {
    this.setState((state) => ({ pageNumber5: state.pageNumber5 + 1 }));
  };
  goToPrevPage6 = () => {
    this.setState((state) => ({ pageNumber6: state.pageNumber6 - 1 }));
  };
  goToNextPage6 = () => {
    this.setState((state) => ({ pageNumber6: state.pageNumber6 + 1 }));
  };
  goToPrevPage7 = () => {
    this.setState((state) => ({ pageNumber7: state.pageNumber7 - 1 }));
  };
  goToNextPage7 = () => {
    this.setState((state) => ({ pageNumber7: state.pageNumber7 + 1 }));
  };
  goToPrevPage8 = () => {
    this.setState((state) => ({ pageNumber8: state.pageNumber8 - 1 }));
  };
  goToNextPage8 = () => {
    this.setState((state) => ({ pageNumber8: state.pageNumber8 + 1 }));
  };
  goToPrevPage9 = () => {
    this.setState((state) => ({ pageNumber9: state.pageNumber9 - 1 }));
  };
  goToNextPage9 = () => {
    this.setState((state) => ({ pageNumber9: state.pageNumber9 + 1 }));
  };
  goToHome = () => {
    history.push("/");
  };
  render() {
    const { pageNumber, numPages } = this.state;
    const {
      pageNumber1,
      numPages1,
      pageNumber2,
      numPages2,
      pageNumber3,
      numPages3,
      pageNumber4,
      numPages4,
      pageNumber5,
      numPages5,
      pageNumber6,
      numPages6,
      pageNumber7,
      numPages7,
      pageNumber8,
      numPages8,
      pageNumber9,
      numPages9,
    } = this.state;
    const responsive = {
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
      },
    };
    return (
      <div className="fin-wrap">
        <nav class="breadcrumb">
          <div className="container d-flex">
            <a class="breadcrumb-item" onClick={this.goToHome}>
              <i className="fas fa-home"></i>
            </a>
            <span class="breadcrumb-item active">
              {window.strings.HEADER.ABOUT}
            </span>
            <span class="breadcrumb-item active">
              {window.strings.HEADER.OUR_FINANCIAL_ANNUAL_REPORTS}
            </span>
          </div>
        </nav>
        <Carousel
          className="fin-slide"
          swipeable={false}
          draggable={false}
          showDots={false}
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          // autoPlay={this.props.deviceType !== "mobile" ? true : false}
          autoPlay={false}
          arrows={false}
          autoPlaySpeed={3000}
          keyBoardControl={true}
          customTransition="all 1.2s ease 0s"
          transitionDuration={1000}
          containerClass="carousel-container"
          removeArrowOnDeviceType={["tablet", "mobile"]}
          deviceType={this.props.deviceType}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
          <div className="abt-banr">
            <img src={REPORT_BANNER} />
          </div>
          {/* <div className="abt-banr">
                            <img src={AbtBanner}/>
                        </div>
                        <div className="abt-banr">
                            <img src={AbtBanner}/>
                        </div> */}
        </Carousel>
        <div className="container">
          <div className="spad">
            <div className="text-center">
              <h3>{window.strings.HEADER.ABOUT}</h3>
              <h4>{window.strings.HEADER.OUR_FINANCIAL_ANNUAL_REPORTS}</h4>
            </div>
            <div className="img-gallery">
              <div className="row">
                <div className="col-xl-3 col-sm-6">
                  <div className="rep-load">
                    <a href={FINANCIAL22}>
                      <i className="fas fa-cloud-download-alt"></i>
                    </a>
                  </div>
                  <div className="rep-wrap">
                    <Document
                      width="300px"
                      height="500px"
                      file={FINANCIAL22}
                      onLoadSuccess={this.onDocumentLoad6}
                    >
                      <Page pageNumber={pageNumber8} />
                    </Document>
                    <div className="page-box">
                      <button className="btn-lef" onClick={this.goToPrevPage8}>
                        &#8592;
                      </button>
                      {/* <p> {pageNumber} of {numPages}</p> */}
                      <button className="btn-rit" onClick={this.goToNextPage8}>
                        &#8594;
                      </button>
                    </div>
                    <span>
                      <h6>{window.strings.ABOUT.FINANCIAL_REPORT}</h6>
                      <p>2022</p>
                    </span>
                  </div>
                </div>
                <div className="col-xl-3 col-sm-6">
                  <div className="rep-load">
                    <a href={ANNUAL22}>
                      <i className="fas fa-cloud-download-alt"></i>
                    </a>
                  </div>
                  <div className="rep-wrap">
                    <Document
                      width="300px"
                      height="500px"
                      file={ANNUAL22}
                      onLoadSuccess={this.onDocumentLoad6}
                    >
                      <Page pageNumber={pageNumber9} />
                    </Document>
                    <div className="page-box">
                      <button className="btn-lef" onClick={this.goToPrevPage9}>
                        &#8592;
                      </button>
                      {/* <p> {pageNumber} of {numPages}</p> */}
                      <button className="btn-rit" onClick={this.goToNextPage9}>
                        &#8594;
                      </button>
                    </div>
                    <span>
                      <h6>{window.strings.ABOUT.ANN_REPORT}</h6>
                      <p>2022</p>
                    </span>
                  </div>
                </div>
                <div className="col-xl-3 col-sm-6">
                  <div className="rep-load">
                    <a href={FINANCIAL21}>
                      <i className="fas fa-cloud-download-alt"></i>
                    </a>
                  </div>
                  <div className="rep-wrap">
                    <Document
                      width="300px"
                      height="500px"
                      file={FINANCIAL21}
                      onLoadSuccess={this.onDocumentLoad6}
                    >
                      <Page pageNumber={pageNumber6} />
                    </Document>
                    <div className="page-box">
                      <button className="btn-lef" onClick={this.goToPrevPage6}>
                        &#8592;
                      </button>
                      {/* <p> {pageNumber} of {numPages}</p> */}
                      <button className="btn-rit" onClick={this.goToNextPage6}>
                        &#8594;
                      </button>
                    </div>
                    <span>
                      <h6>{window.strings.ABOUT.FINANCIAL_REPORT}</h6>
                      <p>2021</p>
                    </span>
                  </div>
                </div>
                <div className="col-xl-3 col-sm-6">
                  <div className="rep-load">
                    <a href={ANNUAL21}>
                      <i className="fas fa-cloud-download-alt"></i>
                    </a>
                  </div>
                  <div className="rep-wrap">
                    <Document
                      width="300px"
                      height="500px"
                      file={ANNUAL21}
                      onLoadSuccess={this.onDocumentLoad7}
                    >
                      <Page pageNumber={pageNumber7} />
                    </Document>
                    <div className="page-box">
                      <button className="btn-lef" onClick={this.goToPrevPage7}>
                        &#8592;
                      </button>
                      {/* <p> {pageNumber1} of {numPages1}</p> */}
                      <button className="btn-rit" onClick={this.goToNextPage7}>
                        &#8594;
                      </button>
                    </div>
                    <span>
                      <h6>{window.strings.ABOUT.ANN_REPORT}</h6>
                      <p>2021</p>
                    </span>
                  </div>
                </div>
                <div className="col-xl-3 col-sm-6">
                  <div className="rep-load">
                    <a href={FINANCIAL20}>
                      <i className="fas fa-cloud-download-alt"></i>
                    </a>
                  </div>
                  <div className="rep-wrap">
                    <Document
                      width="300px"
                      height="500px"
                      file={FINANCIAL20}
                      onLoadSuccess={this.onDocumentLoad}
                    >
                      <Page pageNumber={pageNumber} />
                    </Document>
                    <div className="page-box">
                      <button className="btn-lef" onClick={this.goToPrevPage}>
                        &#8592;
                      </button>
                      {/* <p> {pageNumber} of {numPages}</p> */}
                      <button className="btn-rit" onClick={this.goToNextPage}>
                        &#8594;
                      </button>
                    </div>
                    <span>
                      <h6>{window.strings.ABOUT.FINANCIAL_REPORT}</h6>
                      <p>2020</p>
                    </span>
                  </div>
                </div>
                <div className="col-xl-3 col-sm-6">
                  <div className="rep-load">
                    <a href={ANNUAL20}>
                      <i className="fas fa-cloud-download-alt"></i>
                    </a>
                  </div>
                  <div className="rep-wrap">
                    <Document
                      width="300px"
                      height="500px"
                      file={ANNUAL20}
                      onLoadSuccess={this.onDocumentLoad1}
                    >
                      <Page pageNumber={pageNumber1} />
                    </Document>
                    <div className="page-box">
                      <button className="btn-lef" onClick={this.goToPrevPage1}>
                        &#8592;
                      </button>
                      {/* <p> {pageNumber1} of {numPages1}</p> */}
                      <button className="btn-rit" onClick={this.goToNextPage1}>
                        &#8594;
                      </button>
                    </div>
                    <span>
                      <h6>{window.strings.ABOUT.ANN_REPORT}</h6>
                      <p>2020</p>
                    </span>
                  </div>
                </div>
                <div className="col-xl-3 col-sm-6">
                  <div className="rep-load">
                    <a href={FINANCIAL19}>
                      <i className="fas fa-cloud-download-alt"></i>
                    </a>
                  </div>
                  <div className="rep-wrap">
                    <Document
                      width="300px"
                      height="500px"
                      file={FINANCIAL19}
                      onLoadSuccess={this.onDocumentLoad}
                    >
                      <Page pageNumber={pageNumber} />
                    </Document>
                    <div className="page-box">
                      <button className="btn-lef" onClick={this.goToPrevPage}>
                        &#8592;
                      </button>
                      {/* <p> {pageNumber} of {numPages}</p> */}
                      <button className="btn-rit" onClick={this.goToNextPage}>
                        &#8594;
                      </button>
                    </div>
                    <span>
                      <h6>{window.strings.ABOUT.FINANCIAL_REPORT}</h6>
                      <p>2019</p>
                    </span>
                  </div>
                </div>
                <div className="col-xl-3 col-sm-6">
                  <div className="rep-load">
                    <a href={ANNUAL19}>
                      <i className="fas fa-cloud-download-alt"></i>
                    </a>
                  </div>
                  <div className="rep-wrap">
                    <Document
                      width="300px"
                      height="500px"
                      file={ANNUAL19}
                      onLoadSuccess={this.onDocumentLoad1}
                    >
                      <Page pageNumber={pageNumber1} />
                    </Document>
                    <div className="page-box">
                      <button className="btn-lef" onClick={this.goToPrevPage1}>
                        &#8592;
                      </button>
                      {/* <p> {pageNumber1} of {numPages1}</p> */}
                      <button className="btn-rit" onClick={this.goToNextPage1}>
                        &#8594;
                      </button>
                    </div>
                    <span>
                      <h6>{window.strings.ABOUT.ANN_REPORT}</h6>
                      <p>2019</p>
                    </span>
                  </div>
                </div>
                <div className="col-xl-3 col-sm-6">
                  <div className="rep-load">
                    <a href={FINANCIAL18}>
                      <i className="fas fa-cloud-download-alt"></i>
                    </a>
                  </div>
                  <div className="rep-wrap">
                    <Document
                      width="300px"
                      height="500px"
                      file={FINANCIAL18}
                      onLoadSuccess={this.onDocumentLoad2}
                    >
                      <Page pageNumber={pageNumber2} />
                    </Document>
                    <div className="page-box">
                      <button className="btn-lef" onClick={this.goToPrevPage2}>
                        &#8592;
                      </button>
                      {/* <p> {pageNumber2} of {numPages2}</p> */}
                      <button className="btn-rit" onClick={this.goToNextPage2}>
                        &#8594;
                      </button>
                    </div>
                    <span>
                      <h6>{window.strings.ABOUT.FINANCIAL_REPORT}</h6>
                      <p>2018</p>
                    </span>
                  </div>
                </div>
                <div className="col-xl-3 col-sm-6">
                  <div className="rep-load">
                    <a href={ANNUAL18}>
                      <i className="fas fa-cloud-download-alt"></i>
                    </a>
                  </div>
                  <div className="rep-wrap">
                    <Document
                      width="300px"
                      height="500px"
                      file={ANNUAL18}
                      onLoadSuccess={this.onDocumentLoad3}
                    >
                      <Page pageNumber={pageNumber3} />
                    </Document>
                    <div className="page-box">
                      <button className="btn-lef" onClick={this.goToPrevPage3}>
                        &#8592;
                      </button>
                      {/* <p> {pageNumber3} of {numPages3}</p> */}
                      <button className="btn-rit" onClick={this.goToNextPage3}>
                        &#8594;
                      </button>
                    </div>
                    <span>
                      <h6>{window.strings.ABOUT.ANN_REPORT}</h6>
                      <p>2018</p>
                    </span>
                  </div>
                </div>
                {/* <div className="col-xl-3 col-sm-6 rep-mt">
                  <div className="rep-load">
                    <a href={FINANCIAL17}>
                      <i className="fas fa-cloud-download-alt"></i>
                    </a>
                  </div>
                  <div className="rep-wrap">
                    <Document
                      width="300px"
                      height="500px"
                      file={FINANCIAL17}
                      onLoadSuccess={this.onDocumentLoad4}
                    >
                      <Page pageNumber={pageNumber4} />
                    </Document>
                    <div className="page-box">
                      <button className="btn-lef" onClick={this.goToPrevPage4}>
                        &#8592;
                      </button>
                      
                      <button className="btn-rit" onClick={this.goToNextPage4}>
                        &#8594;
                      </button>
                    </div>
                    <span>
                      <h6>{window.strings.ABOUT.FINANCIAL_REPORT}</h6>
                      <p>2017</p>
                    </span>
                  </div>
                </div> */}
                {/* <div className="col-xl-3 col-sm-6 rep-mt">
                  <div className="rep-load">
                    <a href={ANNUAL17}>
                      <i className="fas fa-cloud-download-alt"></i>
                    </a>
                  </div>
                  <div className="rep-wrap">
                    <Document
                      width="300px"
                      height="500px"
                      file={ANNUAL17}
                      onLoadSuccess={this.onDocumentLoad5}
                    >
                      <Page pageNumber={pageNumber5} />
                    </Document>
                    <div className="page-box">
                      <button className="btn-lef" onClick={this.goToPrevPage5}>
                        &#8592;
                      </button>
                      <button className="btn-rit" onClick={this.goToNextPage5}>
                        &#8594;
                      </button>
                    </div>
                    <span>
                      <h6>{window.strings.ABOUT.ANN_REPORT}</h6>
                      <p>2017</p>
                    </span>
                  </div>
                </div> */}
                {/* <div className="col-xl-3 col-sm-6 rep-mt">
                  <div className="rep-load"></div>

                  <div className="rep-wrap">
                    <img src={FinReport} />
                    <span>
                      <h6>{window.strings.ABOUT.FINANCIAL_REPORT}</h6>
                      <p>2016</p>
                    </span>
                  </div>
                </div>
                <div className="col-xl-3 col-sm-6 rep-mt">
                  <div className="rep-load"></div>

                  <div className="rep-wrap">
                    <img src={AnnReport} />
                    <span>
                      <h6>{window.strings.ABOUT.ANN_REPORT}</h6>
                      <p>2016</p>
                    </span>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const actionCreators = {};

const connectedOurFinancialReport = connect(
  mapStateToProps,
  actionCreators
)(OurFinancialReport);
export { connectedOurFinancialReport as OurFinancialReport };
